<template>
  <div class="pages">
    <div class="page page1" id="page1">
      <div class="page1_header">
        <div class="page1_title">筹码分布</div>
        <div class="time">{{ date }}</div>
      </div>
      <div class="stock_info">
        <div class="stock_name">{{ stockInfo.stock_name }}</div>
        <div class="stock_code">({{ stockInfo.stock_code }})</div>
      </div>
      <div class="footer">
        <div class="logo">
          <img src="./imgs/logo.png" alt="" />
        </div>
        <div class="tips">
          风险提示:本视频相关内容不对各位读者构成任何<br />投资建议,据此操作,风险自担
        </div>
      </div>
    </div>

    <div class="page page2 none" id="page2">
      <div class="page2_bg">
        <div class="page2_header">
          <div class="price">
            <span>{{ fClosePrice1 }}</span
            >当前价格
          </div>
          <div class="model">顶部单峰密集型</div>
        </div>
        <div class="chip_analyze">
          {{ chipDesc1 }}
        </div>
        <div class="page2_main">
          <div class="chart_title">
            <div>筹码分布趋势</div>
            <div class="day_num">30天</div>
          </div>
          <div class="chart_info">
            <div class="show_date index">
              <span class="index_name">日期：</span
              ><span class="index_value">{{ chipDate1 }}</span>
            </div>
            <div class="average_cost index">
              <span class="index_name">平均成本：</span>
              <span class="index_value">{{ fAvgCost1 }}</span>
            </div>
            <div class="support_level index">
              <span class="index_name">支撑位:</span>
              <span class="index_value">{{ fSupportPrice1 }}</span>
            </div>
            <div class="pressure_level index">
              <span class="index_name">压力位：</span>
              <span class="index_value">{{ fPressPrice1 }}</span>
            </div>
            <div class="profit_ratio">
              <div class="index_name">盈利比例</div>
              <div class="ratio">
                <div class="total_length">
                  <div
                    class="proportion"
                    :style="{ width: fEarningPer1 + '%' }"
                  ></div>
                </div>
              </div>
              <div class="index_value">{{ fEarningPer1 }}%</div>
            </div>
          </div>
          <div class="chart">
            <div class="chart_header">
              <div class="press">压力位</div>
              <div class="cost">平均成本</div>
              <div class="support">支撑位</div>
            </div>
            <div class="d3_chart d3_chip" id="card5_chart1"></div>
          </div>
        </div>
        <div class="footer">
          <div class="tips">
            风险提示:本视频相关内容不对各位读者构成任何<br />
            投资建议,据此操作,风险自担
          </div>
          <div class="icon">
            <img src="./imgs/icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="page page3 none" id="page3">
      <div class="page3_bg">
        <div class="page3_header">
          <div class="price">
            <span>{{ fClosePrice2 }}</span
            >当前价格
          </div>
          <div class="model">顶部单峰密集型</div>
        </div>
        <div class="chip_analyze">{{ chipDesc2 }}</div>
        <div class="page3_main">
          <div class="chart_title">
            <div>主力筹码分布</div>
            <div class="day_num">30天</div>
          </div>
          <div class="chart_info">
            <div class="show_date index">
              <span class="index_name">日期：</span
              ><span class="index_value">{{ chipDate2 }}</span>
            </div>
            <div class="pressure_level index">
              <span class="index_name"></span>
              <span class="index_value"></span>
            </div>
            <div class="average_cost index">
              <span class="index_name">主力成本：</span>
              <span class="index_value">{{ fMainAvgCost }}</span>
            </div>
            <div class="support_level index">
              <span class="index_name">主力获利：</span>
              <span class="index_value">{{ fMainIncRate }}</span>
            </div>

            <div class="profit_ratio">
              <div class="index_name">盈利比例</div>
              <div class="ratio">
                <div class="total_length">
                  <div
                    class="proportion"
                    :style="{ width: fMainEarnPer + '%' }"
                  ></div>
                </div>
              </div>
              <div class="index_value">{{ fMainEarnPer }}%</div>
            </div>
          </div>
          <div class="chart">
            <div class="chart_header">
              <div class="cost">主力成本</div>
            </div>
            <div class="d3_chart d3_chip d3_chip2" id="card5_chart2"></div>
          </div>
        </div>
        <div class="footer">
          <div class="tips">
            风险提示:本视频相关内容不对各位读者构成任何<br />
            投资建议,据此操作,风险自担
          </div>
          <div class="icon">
            <img src="./imgs/icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="page page4 none" id="page4">
      <div class="page4_bg">
        <div class="stock" id="page4_title">
          {{ stockInfo.stock_name + "(" + stockInfo.stock_code + ")" }}
        </div>
        <div class="kChart_box">
          <div class="macd none">
            <span>MA5 {{ ma5 }}</span
            ><span>MA10 {{ ma10 }}</span
            ><span>MA20 {{ ma15 }}</span>
          </div>
          <div class="d3_chart d3_k" id="d3K"></div>
        </div>
        <ul class="k_list">
          <li class="li1">
            <div class="left">5日</div>
            <div class="right">
              <div class="index">
                <div class="index_name">上涨概率:</div>
                <div class="ratio">
                  <div class="total_length">
                    <div
                      :style="{
                        width: trendData.fProbaInc5
                          ? trendData.fProbaInc5 + '%'
                          : -trendData.fProbaInc5 + '%',
                        background:
                          trendData.fProbaInc5 > 0 ? '#F02F32' : '#04B032',
                      }"
                      class="proportion"
                    ></div>
                  </div>
                </div>
                <div class="index_value">{{ trendData.fProbaInc5 }}%</div>
              </div>
              <div class="index">
                <div class="index_name">涨跌幅:</div>
                <div class="ratio">
                  <div class="total_length">
                    <div
                      :style="{
                        width:
                          trendData.fAvgInc5 > 0
                            ? trendData.fAvgInc5 + '%'
                            : -trendData.fAvgInc5 + '%',
                        background:
                          trendData.fAvgInc5 > 0 ? '#F02F32' : '#04B032',
                      }"
                      class="proportion"
                    ></div>
                  </div>
                </div>
                <div class="index_value">{{ trendData.fAvgInc5 }}%</div>
              </div>
            </div>
          </li>
          <li class="li2">
            <div class="left">10日</div>
            <div class="right">
              <div class="index">
                <div class="index_name">上涨概率:</div>
                <div class="ratio">
                  <div class="total_length">
                    <div
                      :style="{
                        width:
                          trendData.fProbaInc10 > 0
                            ? trendData.fProbaInc10 + '%'
                            : -trendData.fProbaInc10 + '%',
                        background:
                          trendData.fProbaInc10 > 0 ? '#F02F32' : '#04B032',
                      }"
                      class="proportion"
                    ></div>
                  </div>
                </div>
                <div class="index_value">{{ trendData.fProbaInc10 }}%</div>
              </div>
              <div class="index">
                <div class="index_name">涨跌幅:</div>
                <div class="ratio">
                  <div class="total_length">
                    <div
                      :style="{
                        width:
                          trendData.fAvgInc10 > 0
                            ? trendData.fAvgInc10 + '%'
                            : -trendData.fAvgInc10 + '%',
                        background:
                          trendData.fAvgInc10 > 0 ? '#F02F32' : '#04B032',
                      }"
                      class="proportion"
                    ></div>
                  </div>
                </div>
                <div class="index_value">{{ trendData.fAvgInc10 }}%</div>
              </div>
            </div>
          </li>
          <li class="li3">
            <div class="left">15日</div>
            <div class="right">
              <div class="index">
                <div class="index_name">上涨概率:</div>
                <div class="ratio">
                  <div class="total_length">
                    <div
                      :style="{
                        width:
                          trendData.fProbaInc15 > 0
                            ? trendData.fProbaInc15 + '%'
                            : -trendData.fProbaInc15 + '%',
                        background:
                          trendData.fProbaInc15 > 0 ? '#F02F32' : '#04B032',
                      }"
                      class="proportion"
                    ></div>
                  </div>
                </div>
                <div class="index_value">{{ trendData.fProbaInc15 }}%</div>
              </div>
              <div class="index">
                <div class="index_name">涨跌幅:</div>
                <div class="ratio">
                  <div class="total_length">
                    <div
                      :style="{
                        width:
                          trendData.fAvgInc15 > 0
                            ? trendData.fAvgInc15 + '%'
                            : -trendData.fAvgInc15 + '%',
                        background:
                          trendData.fAvgInc15 > 0 ? '#F02F32' : '#04B032',
                      }"
                      class="proportion"
                    ></div>
                  </div>
                </div>
                <div class="index_value">{{ trendData.fAvgInc15 }}%</div>
              </div>
            </div>
          </li>
        </ul>
        <div class="footer">
          <div class="tips">
            风险提示:本视频相关内容不对各位读者构成任何<br />
            投资建议,据此操作,风险自担
          </div>
          <div class="icon">
            <img src="./imgs/icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="page page5 none" id="page5">
      <div class="logo_box">
        <img src="./imgs/log2.png" alt="" />
      </div>
      <div class="footer">
        <div class="tips">
          风险提示:本视频相关内容不对各位读者构成任何<br />
          投资建议,据此操作,风险自担
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/utils/d3.css";
import "./kline.less";
import { chipChart } from "@/utils/chipChart";
import { dataProcess } from "@/utils/dataProcess";
import { anyElement } from "@/components/anyElement.js";
import { switchPage } from "@/components/switchPage.js";
import { anyElementV1 } from "@/components/anyElement_1.js";
import { sleep, setRem, getUrlParams } from "@/utils/utils.js";
import { drawK } from "@/components/kChart.js";
import lineChart from "@/components/lineChart/index.vue";
import axios from "axios";
import barDoubleChart from "@/components/barDoubleChart/index.vue";
import barChartHorizen from "@/components/barChartHorizen/index_v1.0.vue";
import $ from "jquery";
import FontFaceObserver from "fontfaceobserver";
import ApiServe from "@/api/api";
export default {
  data() {
    return {
      model_id: null,
      model_name: "",
      ip: "",
      port: "",
      currentPage: 1,
      audioData: [],
      audioLengthList: [],
      pageOneIsPoster: true, //首页是否是封面
      hasEndLogo: true, //尾页是否是logo图

      date: null,
      currentPage: 1,
      pageLastTime: [1, 10, 10, 7, 2], //每一页动画持续时间 由开发规定
      animationOutTime: [1, 1, 1, 1, 0],
      trendData: {},
      chart1Data: [],
      chipDate1: "",
      chipDesc1: "",
      chipDesc2: "",
      fAvgCost1: 0,
      fSupportPrice1: 0,
      fPressPrice1: 0,
      fClosePrice1: 0,
      fEarningPer1: "",
      chart2Data: [],
      chipDate2: "",
      fMainAvgCost: 0,
      fMainIncRate: "",
      fClosePrice2: "",
      fMainEarnPer: "",
      kData: [],
      ma5: "",
      ma10: "",
      ma15: "",
      stockInfo: {},
    };
  },
  watch: {
    allReady(val) {
      if (val.audioData.length > 0) {
        if (!this.ip && !this.port) {
          console.log("没获取到ip,port");
          this.page1In();
        } else {
          const params = {
            ip: this.ip,
            port: this.port,
            offset_x: this.offset_x,
            offset_y: this.offset_y,
            width: this.width,
            height: this.height,
          };
          ApiServe.recordStart(this.newIpPort, params)
            .then(({ code }) => {
              this.page1In();
            })
            .catch((err) => {
              ApiServe.recordRelease(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        }
      }
    },
  },
  computed: {
    allReady() {
      const { dataTime, audioData } = this;
      return {
        dataTime,
        audioData,
      };
    },
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
    pageTime() {
      // //每一页所需要的的动画时间 消失时间 需要判断在每一页时音频时长和持续时长 取较大的那个值
      // //页面持续时长  消失动画时间; 若页面持续时长大于音频时间则直接进pageOut不需要等待
      return this.pageLastTime.map((e, i) => {
        let n =
          i == 0
            ? this.pageOneIsPoster
              ? 0
              : 1
            : i == this.pageLastTime.length - 1
            ? this.hasEndLogo
              ? 2
              : 1
            : 0; //语音只能在视频的第一秒插入所以page1时长加一秒,若pageOneIsPoster是true则不需要加1s,最后一页多留一秒
        return e > this.audioLengthList[i]
          ? e + n + 1 //1为动画出场后展示1s在出场
          : this.audioLengthList[i] + this.animationOutTime[i] + n;
      });
    },
    videoTotalTime() {
      return this.pageTime.reduce(function (prev, curr, idx, arr) {
        return prev + curr;
      }, 0);
    },
  },
  components: { lineChart, barDoubleChart, barChartHorizen },
  methods: {
    getTime() {
      const date = new Date();
      const y = date.getFullYear();
      const m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      this.date = y + "-" + m + "-" + d;
    },

    async awaitTimeLength(a, i) {
      // let t = i == this.pageTime.length - 1 ? 0 : 1;
      let time = (this.pageTime[i] - a - this.animationOutTime[i]) * 1000;
      await sleep(time); //1为动画出场的1s
    },

    async fontLoad() {
      console.log("加载字体");
      const harmonyMidium = new FontFaceObserver("harmonyMidium");
      const harmonyBold = new FontFaceObserver("harmonyBold");
      await Promise.all([
        harmonyMidium.load(null, 30 * 1000),
        harmonyBold.load(null, 30 * 1000),
      ]);
    },

    playNumVaryIn(id) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: 2,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },

    async page1In() {
      console.log("page1 ", new Date());
      this.currentPage = 1;
      await sleep(1000);
      await this.awaitTimeLength(
        this.pageLastTime[this.currentPage - 1],
        this.currentPage - 1
      );
      this.page1Out();
    },

    async page1Out() {
      this.page1Header = anyElementV1({
        targets: document.querySelector(".page1_header"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page1Name = anyElementV1({
        targets: document.querySelector(".page1 .stock_info"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.page1Footer = anyElementV1({
        targets: document.querySelector(".page1 .footer"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });

      this.page1Header.runOut();
      this.page1Name.runOut();
      this.page1Footer.runOut();

      await sleep(this.animationOutTime[this.currentPage - 1] * 1000); //此处为消失动画时间
      $("#page1").addClass("none");
      this.page2In();
    },

    async page2In() {
      this.currentPage = 2;
      console.log("page2 ", new Date());
      $("#page2").removeClass("none");
      this.loadChipChart1("card5_chart1", 0);
      await sleep(10000);
      await this.awaitTimeLength(
        this.pageLastTime[this.currentPage - 1],
        this.currentPage - 1
      );
      this.page2Out();
    },

    async page2Out() {
      this.page2Anime = anyElementV1({
        targets: document.querySelector(".page2_bg"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page2Anime.runOut();
      await sleep(this.animationOutTime[this.currentPage - 1] * 1000);
      $("#page2").addClass("none");
      this.page3In();
    },

    async page3In() {
      this.currentPage = 3;
      console.log("page3 ", new Date());
      $("#page3").removeClass("none");
      this.loadChipChart2("card5_chart2", 0);
      await sleep(10000);
      await this.awaitTimeLength(
        this.pageLastTime[this.currentPage - 1],
        this.currentPage - 1
      );
      this.page3Out();
    },

    async page3Out() {
      this.page3Anime = anyElementV1({
        targets: document.querySelector(".page3_bg"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page3Anime.runOut();
      await sleep(this.animationOutTime[this.currentPage - 1] * 1000);
      $("#page3").addClass("none");
      this.page4In();
    },

    async page4In() {
      this.currentPage = 4;
      console.log("page4 ", new Date());
      $("#page4").removeClass("none");
      this.page4Title = anyElement({
        targets: document.querySelector(".page4 .stock"),
        isSplitTxt: true,
        duration: 500,
        delay: 50,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page4Li1 = anyElement({
        targets: document.querySelector(".page4 .li1"),
        isSplitTxt: false,
        duration: 500,
        delay: 500,
        easing: "easeOutExpo",
        inEffect: "leftIn",
        outEffect: "fadeOut",
      });
      this.page4Li2 = anyElement({
        targets: document.querySelector(".page4 .li2"),
        isSplitTxt: false,
        duration: 500,
        delay: 500,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page4Li3 = anyElement({
        targets: document.querySelector(".page4 .li3"),
        isSplitTxt: false,
        duration: 500,
        delay: 500,
        easing: "easeOutExpo",
        inEffect: "rightIn",
        outEffect: "fadeOut",
      });
      this.page4Footer = anyElement({
        targets: document.querySelector(".page4 .footer"),
        isSplitTxt: false,
        duration: 500,
        delay: 500,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });

      this.page4Title.goIn.restart();
      await sleep(500);
      $("#page4 .macd").removeClass("none");
      this.drawKChart(this.kData);
      await sleep(3000);
      await sleep(500);
      this.page4Li1.goIn.restart();
      await sleep(500);
      this.page4Li2.goIn.restart();
      await sleep(500);
      this.page4Li3.goIn.restart();
      await sleep(1000);
      this.page4Footer.goIn.restart();
      await sleep(1000);

      await this.awaitTimeLength(
        this.pageLastTime[this.currentPage - 1],
        this.currentPage - 1
      );

      this.page4Out();
    },

    async page4Out() {
      this.page4Anime = anyElementV1({
        targets: document.querySelector(".page4_bg"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page4Anime.runOut();

      await sleep(this.animationOutTime[this.currentPage - 1] * 1000);
      $("#page4").addClass("none");
      this.page5In();
    },

    async page5In() {
      this.currentPage = 5;
      console.log("page5 ", new Date());
      $("#page5").removeClass("none");
      this.page5Logo = anyElement({
        targets: document.querySelector(".page5 .logo_box"),
        isSplitTxt: false,
        duration: 500,
        delay: 50,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page5Footer = anyElement({
        targets: document.querySelector(".page5 .footer"),
        isSplitTxt: false,
        duration: 500,
        delay: 50,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });

      this.page5Logo.goIn.restart();
      await sleep(500);
      this.page5Footer.goIn.restart();
      await sleep(1500);
      // await this.awaitTimeLength(
      //   this.pageLastTime[this.currentPage - 1],
      //   this.currentPage - 1
      // );
      this.endRecord();
    },

    //画筹码分布
    async loadChipChart1(id, num) {
      let count = num;
      if (count < this.chart1Data.length) {
        // console.log(this.chart1Data[count]);
        this.chipDate1 = this.chart1Data[count].sUpdateDate; //日期
        this.fAvgCost1 = this.chart1Data[count].fAvgCost; //平均成本
        this.fSupportPrice1 = this.chart1Data[count].fSupportPrice; //支撑位
        this.fPressPrice1 = this.chart1Data[count].fPressPrice; //压力位
        this.fClosePrice1 = this.chart1Data[count].fClosePrice; //当前价
        // this.chipDesc1 = this.chart1Data[count].sEarnConcenDesc; //筹码分布描述
        this.fEarningPer1 = (this.chart1Data[count].fEarningPer * 100).toFixed(
          2
        ); //盈利比例
        chipChart.drawChip({
          id: id,
          data: this.chart1Data[count].dataConverted,
          width: document.documentElement.clientWidth - 40,
          ratio: 1,
        });
        // if (count == 0) {
        //   await sleep(1000);
        // }
        count++;
        await sleep(300);
        this.loadChipChart1(id, count);
      }
      // console.log(this.chart1Data);
    },

    //画主力筹码分布
    async loadChipChart2(id, num) {
      let count = num;
      if (count < this.chart2Data.length) {
        this.chipDate2 = this.chart2Data[count].sUpdateDate; //日期
        this.fMainAvgCost = this.chart2Data[count].fMainAvgCost; //主力平均成本
        this.fMainIncRate =
          (this.chart2Data[count].fMainIncRate * 100).toFixed(2) + "%"; //主力获利空间
        this.fClosePrice2 = this.chart2Data[count].fClosePrice.toFixed(2); //当前价
        // this.chipDesc2 = this.chart2Data[count].sEarnConcenDesc; //筹码分布描述
        this.fMainEarnPer = (this.chart2Data[count].fMainEarnPer * 100).toFixed(
          2
        ); //盈利比例
        chipChart.drawChip({
          id: id,
          data: this.chart2Data[count].dataConvertedMain,
          width: document.documentElement.clientWidth - 40,
          ratio: 1,
        });
        count++;
        await sleep(300);
        this.loadChipChart2(id, count);
      }
    },

    //获取筹码分布数据
    async getChipData() {
      let params = {
        iStartxh: 0,
        iWantnum: 30,
        sDtSecCode: JSON.parse(this.url_extra_params).dt_stock_code,
      };
      const url =
        "https://comm.wedengta.com/?s=chipDist&f=getHisChipDistDetail&req=HisChipDistReq&rsp=HisChipDistRsp&_=1646709097113";
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        encodeURIComponent(url) +
        "&payloads=" +
        encodeURIComponent(JSON.stringify(params));

      await axios
        .get(newUrl)
        .then(({ status, data }) => {
          const chipData = JSON.parse(data.content);
          console.log("chipData", chipData);
          let chipArr = chipData.vtChipDistRsp;
          this.trendData = chipData.stBackTraceRs;
          this.chipDesc1 = chipData.sChangeDesc;
          this.chipDesc2 = chipData.sMainChgDesc;

          // 筹码分布数据处理
          let minMax = dataProcess.getMinMax(chipArr, "vStockChipDist");
          for (var i = 0; i < chipArr.length; i++) {
            chipArr[i].dataConverted = dataProcess.convertData({
              data: chipArr[i].vStockChipDist,
              name: "fPrice",
              value: "fPer",
              avePrice: chipArr[i].fAvgCost,
              closePrice: chipArr[i].fClosePrice,
              pressPrice: chipArr[i].fPressPrice,
              supportPrice: chipArr[i].fSupportPrice,
              xMax: minMax.xMax,
              yMax: minMax.yMax,
              yMin: minMax.yMin,
            });
          }

          //主力筹码分布数据处理
          let mainChipArr = chipArr.filter((d) => d.vtMainChipDist.length > 0);
          let minMax2 = dataProcess.getMinMax(mainChipArr, "vtMainChipDist");
          for (var i = 0; i < mainChipArr.length; i++) {
            mainChipArr[i].dataConvertedMain = dataProcess.convertData({
              data: mainChipArr[i].vtMainChipDist,
              name: "fPrice",
              value: "fPer",
              closePrice: mainChipArr[i].fClosePrice.toFixed(1),
              mainAvgCost: mainChipArr[i].fMainAvgCost.toFixed(1),
              xMax: minMax2.xMax,
              yMax: minMax2.yMax,
              yMin: minMax2.yMin,
            });
          }

          this.chart1Data = JSON.parse(JSON.stringify(chipArr)).reverse();
          this.chart2Data = JSON.parse(JSON.stringify(mainChipArr)).reverse();
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasePcPort({ ip: this.ip, port: this.port });
          }
        });
    },

    //画k线图
    drawKChart(data) {
      const ave5 = this.getAverageValue(5, data).slice(20, 50);
      const ave10 = this.getAverageValue(10, data).slice(20, 50);
      const ave15 = this.getAverageValue(15, data).slice(20, 50);

      this.ma5 = ave5[ave5.length - 1].toFixed(2);
      this.ma10 = ave10[ave10.length - 1].toFixed(2);
      this.ma15 = ave15[ave15.length - 1].toFixed(2);

      const dataCombined = this.combineKAve(
        data.slice(20, 50),
        ave5,
        ave10,
        ave15
      );
      console.log("dataCombined", dataCombined);
      drawK({
        id: "d3K",
        data: dataCombined,
        width: document.documentElement.clientWidth - 40,
        ratio: 0.5,
      });
    },

    //获取均线
    getAverageValue: function (num, data) {
      let averageValue = [];
      let totalSum = 0;
      if (data.length <= num) {
        for (let i = 0; i < data.length; i++) {
          totalSum += data[i].fClose;
          averageValue[i] = totalSum / (i + 1);
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          if (i < num) {
            totalSum += data[i].fClose;
            averageValue[i] = totalSum / (i + 1);
          } else {
            totalSum = 0;
            for (let j = i; j > i - num && j > 0; j--) {
              if (i - j < num) {
                totalSum += data[j].fClose;
              }
            }
            averageValue[i] = totalSum / num;
          }
        }
      }
      return averageValue;
    },

    // 合并均线数据到k线数据
    combineKAve: function (data, ave5, ave10, ave20) {
      var _data = data;
      for (var i = 0; i < _data.length; i++) {
        _data[i].ave5 = ave5[i];
        _data[i].ave10 = ave10[i];
        _data[i].ave20 = ave20[i];
      }
      return _data;
    },

    async getKLineData() {
      let params = {
        sDtSecCode: JSON.parse(this.url_extra_params).dt_stock_code,
        eKLineType: 4,
        iWantnum: 50,
      };
      const url =
        "https://comm.wedengta.com/?s=quote&f=getKLine&req=KLineReq&rsp=KLineRsp";

      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        encodeURIComponent(url) +
        "&payloads=" +
        encodeURIComponent(JSON.stringify(params));
      // const { status, data } = await axios.get(newUrl);
      // const kData = JSON.parse(data.content).vKLineDesc;
      // this.kData = kData;
      // console.log("kData", kData);
      await axios
        .get(newUrl)
        .then(({ status, data }) => {
          const kData = JSON.parse(data.content).vKLineDesc;
          this.kData = kData;
          console.log("kData", kData);
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasePcPort({ ip: this.ip, port: this.port });
          }
        });
    },

    async getData() {
      const that = this;
      let params = {
        model_id: this.model_id,
      };
      if (this.url_extra_params) {
        params = {
          model_id: this.model_id,
          url_extra_params: this.url_extra_params,
        };
      }
      // console.log(JSON.parse(params.url_extra_params));
      await this.getChipData();
      await this.getKLineData();
      if (this.ip) {
        ApiServe.releasePcPort({ ip: this.ip, port: this.port });
      }
    },

    // 获取旁白
    async getAudio() {
      let params = {
        model_id: this.model_id,
      };
      if (this.url_extra_params) {
        params = {
          model_id: this.model_id,
          url_extra_params: JSON.parse(this.url_extra_params),
        };
      }
      await ApiServe.getVideoData(params)
        .then(({ data: { data, code } }) => {
          console.log(data, code);
          if (code == 200) {
            this.audioData = data;
            if (this.pageOneIsPoster) {
              this.audioData.unshift({
                audio_content: "",
                audio_length: 0,
                audio_link: "",
                scene_duration: 1,
                scene_id: "0",
              });
            }
            if (this.hasEndLogo) {
              this.audioData.push({
                audio_content: "",
                audio_length: 2,
                audio_link: "",
                scene_duration: 2,
                scene_id: "0",
              });
            }
            this.audioLengthList = this.audioData.map(
              (item) => item.scene_duration
            );

            if (this.ip && this.audioData.length == 0) {
              ApiServe.recordRelease({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasePcPort({ ip: this.ip, port: this.port });
          }
        });
    },

    async endRecord() {
      let arr = [];
      for (let i = 0; i < this.pageTime.length; i++) {
        if (i == 0) {
          this.pageOneIsPoster ? (arr[i] = 2) : (arr[i] = 1);
        } else {
          arr[i] = this.pageTime[i - 1] + arr[i - 1];
        }
      }
      let audiosOrderList = this.audioData.map((item, index) => {
        return {
          audioLink: item.audio_link,
          timeIndex: this.pageOneIsPoster
            ? index == 1
              ? 2
              : arr[index] - 2
            : arr[index],
          audioLen: item.audio_length,
        };
      });
      if (this.pageOneIsPoster) {
        audiosOrderList.shift();
      }
      if (this.hasEndLogo) {
        audiosOrderList.pop();
      }
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: this.videoTotalTime,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: audiosOrderList,
      };
      console.log("arr", arr);
      console.log("audiosOrderList", audiosOrderList);
      console.log("pageTime", this.pageTime);
      console.log("pageLastTime", this.pageLastTime);
      console.log("audioLengthList", this.audioLengthList);
      console.log("videoTotalTime", this.videoTotalTime);
      await ApiServe.recordStop(this.newIpPort, {
        ip: this.ip,
        port: this.port,
      })
        .then(() => {
          ApiServe.combineAudiosWithBgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordRelease(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordRelease(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordRelease(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
  },
  async mounted() {
    this.model_id = 73;
    this.id = getUrlParams().id || null;
    this.model_name = getUrlParams().model_name || null;
    this.model_category = getUrlParams().model_category || null;
    this.url_extra_params = getUrlParams().url_extra_params || null;
    this.ip = getUrlParams().ip || null;
    this.port = getUrlParams().port || null;
    this.offset_x = getUrlParams().offset_x || null;
    this.offset_y = getUrlParams().offset_y || null;
    this.width = getUrlParams().width || null;
    this.height = getUrlParams().height || null;
    this.source = getUrlParams().source || null;
    this.stockInfo = JSON.parse(this.url_extra_params);
    //每个项目都可以独立设置
    setRem(720);

    this.getTime();

    //字体加载比较慢，等它加载完
    await this.fontLoad();
    await this.getData();
    await this.getAudio();
    // await this.getAudio();
    // this.page1In();
  },
};
</script>

<style lang="less" scoped>
@import "index.less";
</style>
